import { ClientConfigProvider } from '/machinery/ClientConfig'
import { reportClientError } from '/machinery/reportClientError'
import { ReportErrorProvider } from '/machinery/reportErrorProvider'
import { LocationProvider } from '@kaliber/routing'
import { routeMap } from '/routeMap'

// eslint-disable-next-line @kaliber/no-default-export
export default function ClientWrapper({ children, ...props }) {
  const { clientContext } = props

  return (
    <ReportErrorProvider reportError={clientContext.reportError || reportClientError}>
      <ClientConfigProvider config={clientContext.clientConfig}>
        <LocationProvider initialLocation={clientContext.location} {...{ routeMap }}>
          {children}
        </LocationProvider>
      </ClientConfigProvider>
    </ReportErrorProvider>
  )
}
