import { FloatingOverlay as FloatingOverlayBase, FloatingPortal } from '@floating-ui/react'
import { animated, useSpring } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useLocationMatch } from '@kaliber/routing'
import { useEvent } from '/machinery/useEvent'
import { useWindowEventListener } from '/machinery/useWindowEventListener'
import { useFloatingContentRoot, floatingContentId } from '/machinery/FloatingContent'

import mediaStyles from '/cssGlobal/media.css'
import styles from './StickyMenuBar.css'

import circle8group from '/images/logos/circle-8-light-red.raw.svg'

const menuItems = [
  {
    label: 'Services',
    route: routeMap.services
  },
  {
    label: 'Investors',
    route: routeMap.investors
  },
  {
    label: 'Newsroom',
    route: routeMap.newsroom
  },
  {
    label: 'Careers',
    route: routeMap.careers
  },
  {
    label: 'Formula1',
    route: routeMap.collab
  },
  {
    label: 'About us',
    route: routeMap.aboutUs
  },
  {
    label: 'Contact',
    route: routeMap.home,
    hash: 'footer'
  }
]

export function StickyMenuBar({ isVisiblePermanently = false, layoutClassName = undefined }) {
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg) ?? false
  const animation = useMenuBarVisibilitySpring({ isVisiblePermanently })

  return (
    <animated.nav style={animation} className={cx(styles.component, layoutClassName)}>
      <div className={styles.inner}>
        <Logo src={circle8group} layoutClassName={styles.logoLayout} />
        {isViewportLg ? <MenuDesktop /> : <MenuMobile />}
      </div>
    </animated.nav>
  )
}

function MenuDesktop() {
  return (
    <ul className={styles.componentMenuDesktop}>
      {menuItems.map((item, i) => (
        <LinkDesktop
          key={i}
          link={createMenuItemLink(item.route, item.hash)}
          layoutClassName={styles.linkLayout}
          label={item.label}
        />
      ))}
    </ul>
  )
}

function MenuMobile() {
  const root = useFloatingContentRoot({ id: floatingContentId })
  const { route: currentRoute } = useLocationMatch()
  const [isActive, setIsActive] = React.useState(false)

  return (
    <div className={styles.componentMenuMobile}>
      <Hamburger onClick={handleHamburgerClick} layoutClassName={styles.hamburgerLayout} {...{ isActive }} />

      <FloatingPortal {...{ root }}>
        <FloatingOverlayBase lockScroll={isActive} className={cx(styles.overlay, isActive && styles.isActive)}>
          <ul className={styles.menuMobileList}>
            {menuItems.map((item, i) => (
              <LinkMobile
                key={i}
                onClick={(e) => handleLinkClick(e, item.route, currentRoute, item.hash)}
                link={createMenuItemLink(item.route, item.hash)}
                layoutClassName={styles.linkLayout}
                label={item.label}
              />
            ))}
          </ul>
        </FloatingOverlayBase>
      </FloatingPortal>
    </div>
  )

  function handleLinkClick(e, route, currentRoute, hash) {
    const isCurrentRoute = route === currentRoute

    if (isCurrentRoute) {
      setIsActive(false)
    }

    if (isCurrentRoute && !hash) {
      e.preventDefault()
    }
  }

  function handleHamburgerClick() {
    setIsActive(!isActive)
  }
}

function LinkDesktop({ link, label, layoutClassName = undefined }) {
  return (
    <li className={cx(styles.componentLinkDesktop, layoutClassName)}>
      <Link {...{ link, label }} />
    </li>
  )
}

function LinkMobile({ link, label, layoutClassName = undefined, onClick }) {
  return (
    <li className={cx(styles.componentLinkMobile, layoutClassName)}>
      <Link {...{ link, label, onClick }} />
    </li>
  )
}

function Link({ link, label, onClick = undefined }) {
  return (
    <a
      aria-label={`Click to navigate to ${label}`}
      href={link}
      {...{ onClick }}
      className={styles.componentLink}
    >
      {label}
    </a>
  )
}

function Hamburger({ onClick, isActive, layoutClassName = undefined }) {
  return (
    <button className={cx(styles.componentHamburger, layoutClassName)} {...{ onClick }}>
      <span className={cx(styles.hamburger, isActive && styles.isActive)} />
    </button>
  )
}

function Logo({ src, layoutClassName }) {
  return (
    <a className={cx(styles.componentLogo, layoutClassName)} href={routeMap.home()} aria-label='Home'>
      <animated.span
        role='presentation'
        className={styles.logoElement}
        dangerouslySetInnerHTML={{ __html: src }}
        aria-label='Circle 8 Group logo'
      />
    </a>
  )
}

function useMenuBarVisibilitySpring({ isVisiblePermanently }) {
  const [spring, api] = useSpring(() => ({
    y: isVisiblePermanently ? '0%' : '-100%'
  }))


  const onScrollEvent = useEvent(handleScroll)

  useWindowEventListener('scroll', onScrollEvent)

  function handleScroll() {
    const { scrollTop } = document.documentElement

    if (!isVisiblePermanently) {
      api.start({ y: scrollTop > 100 ? '0%' : '-100%' })
    }
  }

  return spring
}

function createMenuItemLink(route, hash) {
  return [route(), hash].filter(Boolean).join('#')
}
