import { useIsomorphicLayoutEffect } from 'react-spring'
import { useEvent } from './useEvent'

export function useWindowEventListener(
  name,
  callback,
  { immediate = true, layoutEffect = false } = {}
) {
  const useEffect = layoutEffect
    ? useIsomorphicLayoutEffect
    : React.useEffect

  const onCallbackEvent = useEvent(callback)

  useEffect(
    () => {
      immediate && onCallbackEvent()

      window.addEventListener(name, onCallbackEvent)

      return () => {
        window.addEventListener(name, onCallbackEvent)
      }
    },
    [name, immediate, onCallbackEvent]
  )
}
